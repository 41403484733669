import React from "react";

export const Logo = () => {
    return (
        <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1_444" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="32">
                <path d="M35.3824 0H0.794189V32H35.3824V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1_444)">
                <path d="M17.8807 -0.000438706H0.794189L4.38905 3.28659H17.8807C21.5342 3.28659 25.0378 4.61732 27.6212 6.98603C30.2045 9.35474 31.6559 12.5674 31.6559 15.9173C31.6559 19.2671 30.2045 22.4799 27.6212 24.8485C25.0378 27.2171 21.5342 28.548 17.8807 28.548H9.45852L12.7476 25.5365H17.8831C20.6647 25.5365 23.3323 24.5234 25.299 22.7199C27.2661 20.9164 28.3711 18.4704 28.3711 15.92C28.3711 13.3695 27.2661 10.9235 25.299 9.12007C23.3323 7.31663 20.6647 6.30347 17.8831 6.30347H7.67196L11.3446 9.66879L15.8744 13.819H17.8807C18.4874 13.819 19.0691 14.04 19.498 14.4333C19.927 14.8266 20.1679 15.36 20.1679 15.9162C20.1679 16.4724 19.927 17.0058 19.498 17.3991C19.0691 17.7924 18.4874 18.0134 17.8807 18.0134H15.8805L12.2079 21.3822H17.8807C18.6631 21.3822 19.4377 21.2409 20.1605 20.9664C20.8832 20.6919 21.5399 20.2896 22.0931 19.7824C22.6463 19.2751 23.0851 18.673 23.3845 18.0103C23.6838 17.3476 23.8379 16.6373 23.8379 15.92C23.8379 15.2026 23.6838 14.4924 23.3845 13.8297C23.0851 13.167 22.6463 12.5648 22.0931 12.0576C21.5399 11.5504 20.8832 11.148 20.1605 10.8735C19.4377 10.599 18.6631 10.4577 17.8807 10.4577H17.2783L16.4187 9.66825H16.441L17.8807 9.66476C19.6899 9.66476 21.425 10.3238 22.7043 11.4968C23.9836 12.6697 24.7023 14.2607 24.7023 15.9196C24.7023 17.5784 23.9836 19.1694 22.7043 20.3424C21.425 21.5154 19.6899 22.1744 17.8807 22.1744H11.3396L4.39023 28.5474L0.798005 31.8409H17.8807C22.4859 31.8409 26.9024 30.1635 30.1587 27.1777C33.4151 24.192 35.2443 20.1424 35.2443 15.92C35.2443 11.6975 33.4151 7.64791 30.1587 4.66216C26.9024 1.6764 22.4859 -0.000976562 17.8807 -0.000976562V-0.000438706Z" fill="#00CEBC" />
                <path d="M13.2562 15.9188L0.793335 4.4917V27.3483L4.38789 24.0521L13.2562 15.9188Z" fill="#0096D1" />
            </g>
        </svg>
    )
}